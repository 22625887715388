<template>
  <div>
    <Page :tabList="[]" :getPageList="[]"></Page>
  </div>
</template>
  
  <script>
import Page from "../../components/public/Page.vue";
export default {
  components: {
    Page,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
  
  <style lang="less" scoped >
</style>